<script setup lang="ts">
import useUserStore from '~/store/user'
import Banner77 from '~/components/ui/Banner77.vue'

const { translate } = useTranslations()
const LINKS = [
  {
    label: 'my-profile',
    title: translate('my-profile', 'My Profile'),
    url: 'profile',
    icon: 'user',
    showOnMobile: true,
  },
  {
    label: 'my-orders',
    title: translate('my-orders', 'My Orders'),
    url: 'orders',
    icon: 'delivery-box',
    showOnMobile: true,
  },
  {
    label: 'my-addresses',
    title: translate('my-addresses', 'My Addresses'),
    url: 'addresses',
    icon: 'map-marker',
    showOnMobile: true,
  },
  {
    label: 'privacy-settings',
    title: translate('privacy-settings', 'Privacy Settings'),
    url: 'privacy',
    icon: 'lock',
    showOnMobile: true,
  },
  {
    label: 'sign-out',
    title: translate('sign-out', 'Sign Out'),
    icon: 'log-out',
    showOnMobile: true,
    onClick: async () => {
      await authStore.logout
        .then(() => navigateTo('/login'))
        .catch((error) => {
          console.error(error)
        })
    },
  },
]

const route = useRoute()
const userStore = useUserStore()
const user = ref(userStore.user)
const selectedPage = ref('my-profile')
const routeFullName = route.href.split('/')
const routeName = routeFullName[routeFullName.length - 1]

const onSelectChange = (label: string) => {
  const link = LINKS.value.find((item) => item.label === label)

  if (link) {
    if (link.url) {
      window.location.href = `/NewApp/Default.aspx?app=${link.url}`
    } else if (link.onClick) {
      link.onClick()
    }
  }
}

const getCurrentTitle = () => LINKS.find((item) => item.url === routeName).title

const handleTitle = () =>
  routeName === 'profile' && userStore?.firstName
    ? translate('welcome-user', 'Welcome') + userStore.userFullName
    : getCurrentTitle()
</script>

<template>
  <div>
    <Banner77
      class="header-banner hidden md:block"
      path="/images/Campaign/2021/Autumn/Category/"
      xl="1920_LP_WED_x{ratio}.jpg"
      l="1440_LP_WED_x{ratio}.jpg"
      m="1024_LP_WED_x{ratio}.jpg"
      s="768_LP_WED_x{ratio}.jpg"
      xs="375_LP_WED_x{ratio}.jpg"
      :webp="true"
    >
      <div class="container">
        <div class="block">
          <h2 class="text-center text-white heading-2 text-uppercase">
            {{ handleTitle() }}
          </h2>
        </div>
      </div>
    </Banner77>
    <div class="py-6 px-4 md:p-6 block md:hidden">
      <h2 class="text-left heading-4 mb-4">
        {{ translate('welcome-user', 'Welcome {name} ') + user.firstName }}
      </h2>
      <select
        id="links"
        v-model="selectedPage"
        class="w-full px-4 bg-white border border-solid links-select border-darkLighter-400 h-11 body-14-aa"
        name="links"
        @change="onSelectChange($event.target.value)"
      >
        <option v-for="(item, index) in LINKS.filter((link) => link.showOnMobile)" :key="index" :value="item.label">
          {{ item.title }}
        </option>
      </select>
    </div>
    <div class="p-4 md:py-8 grid77 body-14-aa bg-pale-100 md:bg-white">
      <div class="hidden col-span-3 md:block">
        <div v-for="(item, index) in LINKS" :key="index" class="my-3 flex items-center menu-link">
          <i class="icon77 mr-3" :class="`icon77-${item.icon}`" />
          <NuxtLinkLocale v-if="item.url" :to="`/account/${translateURL(item.url)}`">
            {{ item.title }}
          </NuxtLinkLocale>
          <button v-else-if="item.onClick" type="button" @click="item.onClick()">
            {{ item.title }}
          </button>
        </div>
      </div>
      <div class="col-span-12 md:col-span-9">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.header-banner {
  .links-select {
    background-image: url('https://cdn.77diamonds.com/images/shop/icons/chevron-down.svg');
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
  }
}
.menu-link {
  a {
    text-decoration: none;
  }
  a.router-link-exact-active {
    text-decoration: underline;
  }
}
</style>
